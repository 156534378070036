import React from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './styles.scss';

export const ImageCarousel = ({ children }) => {
  return (
    <div className="image-carousel">
      {
        children.length > 1 ?
          <Carousel
            style={{ width: '100%' }}
            hasIndexBoard={false}
            hasSizeButton={false}
            hasMediaButton={false}
            hasThumbnails={false}
            hasLeftButton={(children.length > 1) ? 'centerLeft' : false}
            hasRightButton={(children.length > 1) ? 'centerRight' : false}
            shouldSwipeOnMouse={(children.length > 1) ? true : false}
            leftIcon={<FontAwesomeIcon className="ml-2" color="#f7961e" icon={faChevronLeft} size="2x" />}
            rightIcon={<FontAwesomeIcon className="mr-2" color="#f7961e" icon={faChevronRight} size="2x" />}
            isAutoPlaying={true}
          >
            {children}
          </Carousel>
          :
          children
      }
    </div>
  )
}

export default ImageCarousel